<template>
  <EditCalendarStyled>
    <ModelEditor
      v-if="calendar"
      class="edit-calendar"
      :title="title"
      :model="calendar"
      :errors="errors"
      :canSave="isDirty && !!selectedOwner"
      :canCancel="isDirty"
      :loading="$apollo.loading"
      @save="update"
      @cancel="restore"
    >
      <UpdateCalendarStyled>
        <InputField :labelWidth="6" class="name">
          <template v-slot:input>
            <input type="text" v-model.trim="$v.name.$model" placeholder="Name" />
          </template>
          <template v-slot:label>
            <span>Name</span>
          </template>
          <template v-slot:errors v-if="$v.name.$dirty">
            <div class="error" v-if="!$v.name.required">Field is required</div>
            <div class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</div>
          </template>
        </InputField>
        <div class="custom-select">
          <CustomSingleSelect
            class="select-calendar-type"
            track-by="id"
            :options="availableCalendarTypes"
            :selectedOption="selectedCalendarType"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
            :placeholder="'Select Calendar Type'"
            @selectedFilter="selectCalendarType"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $tc('type', 1) }}:</ShowAsLabel>
            </template>
          </CustomSingleSelect>
        </div>
        <div class="custom-select">
          <CustomSingleSelect
            class="select-owner-type"
            track-by="id"
            :options="availableOwnerTypes"
            :selectedOption="selectedOwnerType"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
            :placeholder="'Select Owner Type'"
            @selectedFilter="selectOwnerType"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $tc('owner', 1) }} {{ $tc('type', 1) }}:</ShowAsLabel>
            </template>
          </CustomSingleSelect>
        </div>
        <div class="custom-select">
          <CustomMultiSelect
            class="select-owner"
            track-by="id"
            openDirection="bottom"
            :options="availableOwnersMapped"
            :value="selectedOwner"
            :shouldNotResetSelectedOption="true"
            :disabled="!selectedOwnerType"
            :closeOnSelect="true"
            :searchable="true"
            :is-loading="availableOwnersIsLoading"
            :placeholder="'Select Owner'"
            :modelName="selectedOwnerTypeId"
            @select="selectOwner"
            @search-change="debouncedfindOwners"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $tc('owner', 1) }}:</ShowAsLabel>
            </template>
          </CustomMultiSelect>
        </div>
        <div class="owner-link" v-if="ownerLink">
          <router-link :to="ownerLink.to"><ExternalLinkIcon /> {{ ownerLink.label }} </router-link>
        </div>
      </UpdateCalendarStyled>
      <ScheduleManagerStyled>
        <div class="schedule" v-for="schedule in calendar.schedules" :key="schedule.id">
          <span class="name">
            {{ $tc('schedule', 1) }}:
            {{ schedule.name }}
          </span>
          <span class="assets">
            <span> {{ $tc('asset', schedule.assignedAssets.length) }}:&nbsp;</span>
            <span v-if="schedule.assignedAssets.length > 0">
              {{ schedule.assignedAssets.length }}
            </span>
            <span v-else> {{ $t('all') }} ({{ assetsByRecipient.length }}) </span>
          </span>
          <XIcon @click="confirmDeleteSchedule(schedule)" />
        </div>
      </ScheduleManagerStyled>
    </ModelEditor>
    <CalendarStyled v-if="calendar">
      <Calendar :calendar="calendar" @reload="reloadCalendar" />
    </CalendarStyled>
  </EditCalendarStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelEditor from '@/components/misc/ModelEditor'
import { InputField } from '@common/components'
import { required, minLength } from 'vuelidate/lib/validators'
import { debounce } from 'vue-debounce'
import chroma from 'chroma-js'
import { XIcon, ExternalLinkIcon } from 'vue-feather-icons'
import { FlashMessages } from '@common/singletons'

import { CustomSingleSelect, CustomMultiSelect } from '@common/components'

import USERS_SEARCH_QUERY from '#/graphql/search/users.gql'
import ORGANIZATIONS_SEARCH_QUERY from '#/graphql/search/organizations.gql'
import SUBSIDIARIES_SEARCH_QUERY from '#/graphql/search/subsidiaries.gql'
import CALENDAR_QUERY from '#/graphql/calendar/show.gql'
import UPDATE_CALENDAR_MUTATION from '#/graphql/calendar/updateCalendar.gql'
import DELETE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/delete.gql'
import DELETE_MANY_SCHEDULE_RECURRENCES_MUTATION from '#/graphql/calendar/schedule/scheduleRecurrence/deleteMany.gql'
import DELETE_MANY_SCHEDULE_ASSIGNED_ASSETS_MUTATION from '#/graphql/calendar/schedule/assignedAsset/deleteMany.gql'
import ASSETS_BY_RECIPIENT_QUERY from '#/graphql/operations/assets/_assetsByRecipient.gql'

import { Calendar } from '@common/components'

const EditCalendarStyled = styled('div')`
  display: grid;
  .edit-calendar {
    > .main {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`

const CalendarStyled = styled('div')`
  margin: 2rem;
  min-height: 60rem;
  background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  backdrop-filter: blur(7px);
`

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const UpdateCalendarStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;

  .owner-link > a {
    display: flex;
    align-items: center;
    .feather {
      margin-right: 0.5rem;
    }
  }

  .custom-select {
    max-width: 30rem;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black};

    .multiselect__content .multiselect__element .multiselect__option.multiselect__option--highlight {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.tableWidgetHightlight};

      &.multiselect__option--disabled {
        background: ${({ theme }) => theme.colors.tableWidgetHightlight};
      }
    }
    > .select-owner {
      .multiselect__content-wrapper {
        height: 140px;
      }
    }
  }
`

const ScheduleManagerStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 2rem;
  grid-gap: 0.25rem;
  padding: 0.5rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};

  .schedule {
    display: grid;
    grid-template-columns: 1fr 1fr 2rem;
    background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};

    > span {
      padding: 0 0.5rem;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
    > .feather {
      margin: 0.25rem;
    }
  }
`

const AVAILABLE_CALENDAR_TYPES = [
  {
    id: 'SHIFT_PLAN',
    label: 'Shift plan',
  },
  {
    id: 'OTHER',
    label: 'Other',
    $isDisabled: true,
  },
]

export default {
  components: {
    EditCalendarStyled,
    Calendar,
    CalendarStyled,
    UpdateCalendarStyled,
    ScheduleManagerStyled,
    ModelEditor,
    InputField,
    CustomSingleSelect,
    CustomMultiSelect,
    ShowAsLabel,
    XIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      name: '',
      alias: '',
      errors: [],
      calendar: null,
      assetsByRecipient: [],
      title: this.$tc('navigation.archon.calendar'),
      isLoading: false,
      isUpdating: false,
      selectedOwnerTypeId: null,
      selectedOwnerId: null,
      availableOwners: [],
      availableOwnersIsLoading: false,
      searchQuery: undefined,
      selectedCalendarTypeId: null,
      availableCalendarTypes: AVAILABLE_CALENDAR_TYPES,
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },
  computed: {
    canSave() {
      // return !this.$v.$invalid && !this.isUpdating && Boolean(this.hasOwner)
      return true
    },
    isDirty() {
      const isNameDirty = this.calendar.name !== this.$v.name.$model
      // const isTypeDirty = this.calendar.type !== this.selectedCalendarType.id
      const isOwnerTypeDirty = this.calendar.ownerType !== this.selectedOwnerType.id
      const isOwnerIdDirty = this.selectedOwner !== undefined && this.calendar.ownerId !== this.selectedOwner.id
      return isNameDirty || isOwnerTypeDirty || isOwnerIdDirty
    },
    hasOwner() {
      return this.selectedOwnerType?.id && this.selectedOwner?.id
    },
    selectedCalendarType() {
      return this.availableCalendarTypes.find(f => f.id === this.selectedCalendarTypeId)
    },
    selectedOwnerType() {
      return this.availableOwnerTypes.find(f => f.id === this.selectedOwnerTypeId)
    },
    selectedOwner() {
      return this.availableOwnersMapped.find(f => f.id === this.selectedOwnerId)
    },
    ownerLink() {
      if (!this.selectedOwner?.id) {
        return null
      }
      if (this.selectedOwnerType?.id === 'Organization') {
        return {
          to: {
            name: 'organization',
            params: {
              id: this.selectedOwner.id,
            },
          },
          label: this.selectedOwner.label,
        }
      } else if (this.selectedOwnerType?.id === 'Subsidiary') {
        return {
          to: {
            name: 'subsidiary',
            params: {
              id: this.selectedOwner.id,
            },
          },
          label: this.selectedOwner.label,
        }
      } else if (this.selectedOwnerType?.id === 'SuUserbsidiary') {
        return {
          to: {
            name: 'user',
            params: {
              id: this.selectedOwner.id,
            },
          },
          label: this.selectedOwner.label,
        }
      }
      return null
    },
    availableOwnerTypes() {
      return [
        {
          id: 'Organization',
          label: 'Organization',
          $isDisabled: true,
        },
        {
          id: 'Subsidiary',
          label: 'Subsidiary',
        },
        {
          id: 'User',
          label: 'User',
        },
      ]
    },
    searchQueryDocument() {
      let doc = USERS_SEARCH_QUERY
      if (this.selectedOwnerType?.id === 'Organization') {
        doc = ORGANIZATIONS_SEARCH_QUERY
      } else if (this.selectedOwnerType?.id === 'Subsidiary') {
        doc = SUBSIDIARIES_SEARCH_QUERY
      }
      return doc
    },
    availableOwnersMapped() {
      return this.availableOwners.map(owner => ({
        id: owner.id,
        label: owner.__typename === 'Subsidiary' ? `${owner.organization.alias} - ${owner.name}` : owner.name,
      }))
    },
  },
  created() {
    this.findOwners = debounce(search => {
      this.debouncedfindOwners(search)
    }, 150)
  },
  methods: {
    reloadCalendar() {
      this.$apollo.queries.calendar.refetch()
    },
    confirmDeleteSchedule(schedule) {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'Schedule',
          instance: schedule,
          labelKey: 'name',
          onConfirm: this.deleteSchedule,
          onConfirmArgs: schedule,
        },
        this.onCloseSettings,
      )
    },
    async deleteSchedule(schedule) {
      await this.$apollo.mutate({
        mutation: DELETE_MANY_SCHEDULE_RECURRENCES_MUTATION,
        variables: {
          scheduleId: schedule?.id,
        },
      })
      await this.$apollo.mutate({
        mutation: DELETE_MANY_SCHEDULE_ASSIGNED_ASSETS_MUTATION,
        variables: {
          scheduleId: schedule?.id,
        },
      })
      await this.$apollo.mutate({
        mutation: DELETE_SCHEDULE_MUTATION,
        variables: {
          where: {
            id: schedule?.id,
          },
        },
      })
      this.reloadCalendar()
      return true
      // const calendar = res?.data?.updateCalendar
      // if(calendar?.id) {
      //   this.$emit('reload')
      // }
    },
    async update() {
      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_CALENDAR_MUTATION,
          variables: {
            data: {
              ownerType: {
                set: this.selectedOwnerTypeId,
              },
              ownerId: {
                set: this.selectedOwnerId,
              },
              type: {
                set: this.selectedCalendarTypeId,
              },
              name: {
                set: this.name,
              },
            },
            where: {
              id: this.calendar?.id,
            },
          },
        })
        const calendar = res?.data?.updateCalendar
        if (calendar?.id) {
          this.$emit('reload')
        }
        FlashMessages.$emit('success', `Calendar ${calendar.name} saved successfully`, {
          timeout: 3000,
        })

        this.calendar.name = calendar?.name
        this.calendar.ownerType = calendar?.owner?.__typename
        this.calendar.ownerId = calendar?.owner?.id
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    selectOwnerType({ id }) {
      this.selectedOwnerTypeId = id
      this.findOwners('')
    },
    selectOwner({ id }) {
      this.selectedOwnerId = id
    },
    selectCalendarType({ id }) {
      this.selectedCalendarTypeId = id
    },
    restore() {
      this.name = this.calendar?.name
      this.selectedOwnerTypeId = this.calendar?.owner?.__typename
      this.selectedOwnerId = this.calendar?.owner?.id
      this.selectedCalendarTypeId = this.calendar?.type
      this.debouncedfindOwners('')
    },
    async debouncedfindOwners(search) {
      this.searchQuery = search
      const results = await this.$apollo.query({
        query: this.searchQueryDocument,
        variables: {
          search: this.searchQuery,
        },
      })
      const data = results?.data
      if (!data) {
        return
      }
      const foundOwners = data[Object.keys(data)[0]] || []
      this.availableOwners = foundOwners
    },
  },
  apollo: {
    calendar: {
      query: CALENDAR_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params?.id,
          },
        }
      },
      skip() {
        return !this.$route.params?.id
      },
      manual: true,
      result({ data }) {
        this.calendar = data?.calendar
        this.restore()
      },
    },
    assetsByRecipient: {
      query: ASSETS_BY_RECIPIENT_QUERY,
      variables() {
        return {
          where: {
            recipientId: this.calendar.ownerId,
            recipientType: this.calendar.ownerType,
          },
        }
      },
      skip() {
        return !this.calendar?.ownerType || !this.calendar?.ownerId
      },
    },
  },
}
</script>
